import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "yellow" }} spin />
);

export default function SpinLoader() {
  return <Spin indicator={antIcon} />;
}
