import { Modal } from "antd";
import "./styles.css";
import { ReactNode } from "react";

const ModalComp = ({
  width = "30rem",
  className = "",
  title,
  children,
  isModalOpen,
  setIsModalOpen,
  closeIcon = true,
}: {
  width?: string;
  className?: string;
  title?: string;
  children: ReactNode;
  isModalOpen: boolean;
  closeIcon?: boolean;
  setIsModalOpen?: (a: boolean) => void;
}) => {
  const handleCancel = () => {
    setIsModalOpen?.(false);
  };

  return (
    <Modal
      closeIcon={closeIcon}
      centered
      title={title}
      footer={false}
      width={`min(80vw,${width})`}
      className={`modal__content ${className}`}
      open={isModalOpen}
      onCancel={handleCancel}>
      {children}
    </Modal>
  );
};

export default ModalComp;
