import { ReactNode, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { BraveWalletAdapter } from "@solana/wallet-adapter-brave";
import { LedgerWalletAdapter } from "@solana/wallet-adapter-ledger";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { store } from "../redux/store";
import { _heliusRPC } from "../constant";
import { AuthProvider } from "../contextApi/AuthContext";

const Providers = ({ children }: { children: ReactNode }) => {
  const providers = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new BraveWalletAdapter(),
      new SolflareWalletAdapter(),
      new LedgerWalletAdapter(),
    ],
    []
  );

  return (
    <ReduxProvider store={store}>
      <ConnectionProvider endpoint={_heliusRPC}>
        <WalletProvider wallets={providers} autoConnect>
          <WalletModalProvider>
            <AuthProvider>{children}</AuthProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ReduxProvider>
  );
};

export default Providers;
