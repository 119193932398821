// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { logo3x } from "../../assets";
import { is3dLink, isMP4Link } from "../../utils";

function ModelViewer({
  url,
  heightWidth,
  borderRadius,
  height = "100%",
  width = "100%",
}: {
  url: string | undefined | null;
  heightWidth?: boolean;
  borderRadius?: boolean;
  height?: string;
  width?: string;
}) {
  return is3dLink(url) ? (
    <section
      style={{
        height: "100%",
        width: "100%",
      }}
      onClick={(e) => e.stopPropagation()}>
      <model-viewer
        auto-rotate-delay='0'
        style={{
          ...(borderRadius && { borderRadius: "20px" }),
          ...(heightWidth && { height, width }),
          background: "rgba(0, 0, 0, .1)",
        }}
        auto-rotate='true'
        autoplay='true'
        camera-controls='true'
        src={url}
        ar-status='not-presenting'></model-viewer>
    </section>
  ) : isMP4Link(url) ? (
    <video controls={false} autoPlay muted loop>
      <source src={url} type='video/mp4' />
      <source src={url} type='video/ogg' />
      Your browser does not support the video tag.
    </video>
  ) : (
    <img src={url || logo3x} alt={"Image"} />
  );
}

export default ModelViewer;

{
  /* <video controls autoplay>
  <source src="movie.mp4" type="video/mp4">
  <source src="movie.ogg" type="video/ogg">
  Your browser does not support the video tag.
</video> */
}
