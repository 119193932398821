export function getLocalStorageItem(key: string): string | null {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : item;
}

export function setLocalStorageItem(key: string, value: string): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key);
}

export function getAccessToken(): string | null {
  return getLocalStorageItem("accessToken");
}

export function deleteTokens(): void {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
}

export function setAccessToken(value: string): void {
  setLocalStorageItem("accessToken", value);
}

export function getRefreshToken(): string | null {
  return getLocalStorageItem("refreshToken");
}

export function setRefreshToken(value: string): void {
  setLocalStorageItem("refreshToken", value);
}

export function getResumeSwitchKey(): string | null {
  return localStorage.getItem("resumeSwitch");
}
