// random id
export function generateUUID() {
  const crypto = window.crypto; // For browser compatibility

  if (!crypto || !crypto.getRandomValues) {
    throw new Error("Crypto API not available.");
  }

  // Create an array to hold 16 bytes (128 bits)
  const byteArray = new Uint8Array(16);

  // Fill the array with random values
  crypto.getRandomValues(byteArray);

  // Set the version (4) and variant (random) bits
  byteArray[6] = (byteArray[6] & 0x0f) | 0x40; // Version 4
  byteArray[8] = (byteArray[8] & 0x3f) | 0x80; // Variant RFC4122

  // Convert the byte array to a hexadecimal string
  const uuid = Array.from(byteArray)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  // Format the UUID
  return `${uuid.substring(0, 8)}-${uuid.substring(8, 12)}-${uuid.substring(
    12,
    16
  )}-${uuid.substring(16, 20)}-${uuid.substring(20)}`;
}

export function capitalizeWords(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function addBtnInWalletDropdownStart(
  option: "add" | "remove",
  btnText: "Profile" | "Sign In",
  onClick: () => void
) {
  const className = `${btnText.replaceAll(" ", "")}-list-item`;
  const myItem = document.querySelector("." + className);
  const dropdownList = document.querySelector(".wallet-adapter-dropdown-list");
  if (option === "add" && myItem === null) {
    if (dropdownList) {
      const newLi = document.createElement("li");
      newLi.className = `wallet-adapter-dropdown-list-item ${className}`;
      newLi.setAttribute("role", "menuitem");
      newLi.textContent = btnText;
      newLi.addEventListener("click", onClick);
      dropdownList.insertBefore(newLi, dropdownList.firstChild);
    }
  } else if (option === "remove" && myItem) {
    dropdownList?.removeChild(myItem);
  }
}

export function addBtnInWalletDropdownEnd(
  option: "add" | "remove",
  btnText: "Log Out",
  onClick: () => void
) {
  const className = `${btnText.replaceAll(" ", "")}-list-item`;
  const myItem = document.querySelector("." + className);
  const dropdownList = document.querySelector(".wallet-adapter-dropdown-list");

  if (option === "add" && myItem === null) {
    if (dropdownList) {
      const newLi = document.createElement("li");
      newLi.className = `wallet-adapter-dropdown-list-item ${className}`;
      newLi.setAttribute("role", "menuitem");
      newLi.textContent = btnText;
      newLi.addEventListener("click", onClick);
      dropdownList.appendChild(newLi);
    }
  } else if (option === "remove" && myItem) {
    dropdownList?.removeChild(myItem);
  }
}
