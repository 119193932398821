import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Artist, Switch } from "@rfrsh/brightswitch";
import { RealtimeData, brightSwitchState } from "../../types";
import { openNotification } from "../../utils";
import { RootState } from "../store";
import { apiUrl } from "../../constant";

export const fetchAllArtists = createAsyncThunk(
  "brightSwitch/fetchAllArtists",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const brightSwitch = state.honeycomb.honeycomb?.bright();
    try {
      const artists = await Artist.allWithFilter(brightSwitch!);

      return await Promise.all(
        artists.map(async (artist) => {
          const switchesLength = (await artist.switches()).length;
          return {
            artist,
            switchesLength,
          };
        })
      );
    } catch (error: any) {
      openNotification({
        type: "error",
        title: "Error while fetching artists' data",
      });
      console.error("Error while fetching artists' data:", error);
      throw new Error(error);
    }
  }
);

export const fetchAllSwitches = createAsyncThunk(
  "brightSwitch/fetchAllArtistsSwitches",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const brightSwitch = state.honeycomb.honeycomb?.bright();
    try {
      const switches = await Switch.allWithFilter(brightSwitch!);
      return switches
        .map((sw) => {
          return {
            artistAddress: sw.artist.address().toString(),
            switchAddress: sw.address().toString(),
            image:
              sw.metadata()?.content?.links?.animation_url ||
              sw.metadata()?.content?.links.image,
            total: Number(sw.data().limit),
            claimed: Number(sw.data().minted),
            name: sw?.data()?.name,
          };
        })
        .filter((e) => e.name !== "Test Switch");
    } catch (error: any) {
      openNotification({
        type: "error",
        title: "Error while fetching all switches data",
      });
      console.error("Error while fetching all switches data:", error);
      throw new Error(error);
    }
  }
);

export const fetchArtistSwitches = createAsyncThunk<Switch[], Artist>(
  "brightSwitch/fetchArtistSwitches",
  async (artist) => {
    try {
      const switches = await artist.switches();

      const now = Date.now();
      const switchInSixHours = switches.filter((item) => {
        const startedAt = Number(item.data().startedAt) * 1000;
        return startedAt >= now && startedAt <= now + startedAt;
      });

      const pastSwitches = switches.filter((item) => {
        const startedAt = Number(item.data().startedAt) * 1000;
        return startedAt < now;
      });

      const otherSwitches = switches.filter((item) => {
        const startedAt = Number(item.data().startedAt) * 1000;
        return startedAt > now + startedAt;
      });

      return [switchInSixHours, otherSwitches, pastSwitches]
        .flat()
        .filter((e) => e.data().name !== "Test Switch");
    } catch (error: any) {
      openNotification({
        type: "error",
        title: "Error while fetching an artist's switches",
      });
      console.error("Error while fetching an artist's switches:", error);
      throw new Error(error);
    }
  }
);

export const fetchTransactions = createAsyncThunk(
  "brightSwitch/fetchTransactions",
  async () => {
    try {
      const data = await fetch(apiUrl + "brightswitch/history").then((e) =>
        e.json()
      );
      const result = data.result;

      if (result.length === 0) return [];
      const arr: any = [
        { title: "Wallet address", list: [] },
        { title: "Edition Claimed", list: [] },
        { title: "Transaction Signature", list: [] },
        { title: "Time", list: [] },
      ];
      result.forEach((txn: RealtimeData) => {
        arr[0].list.push(txn.user);
        arr[1].list.push(txn.address);
        arr[2].list.push(
          `https://xray.helius.xyz/tx/${txn._id.split("_")[0]}${
            process.env.REACT_APP_DEV_QUERY || ""
          }`
        );
        arr[3].list.push(new Date(txn.timestamp).toLocaleString());
      });

      return arr;
    } catch (error: any) {
      openNotification({
        type: "error",
        title: "Error while fetching Transactions data",
      });
      console.error("Error while fetching Transactions data:", error);
      throw new Error(error);
    }
  }
);

export const fetchBurnersMetadata = createAsyncThunk(
  "brightSwitch/fetchBurnersMetadata",
  async () => {
    try {
      const data = (
        await fetch(apiUrl + "brightswitch/metadata").then((e) => e.json())
      ).result;

      return data;
    } catch (error: any) {
      openNotification({
        type: "error",
        title: "Error while fetching burners metadata",
      });
      console.error("Error while fetching burners metadata:", error);
      throw new Error(error);
    }
  }
);

const initialState: brightSwitchState = {
  artists: {
    status: "idle",
    error: null,
    data: [],
  },
  allSwitches: {
    status: "idle",
    error: null,
    data: [],
  },
  artistSwitches: {
    status: "idle",
    error: null,
    data: [],
  },
  selectedArtist: {
    status: "idle",
    error: null,
    data: undefined,
  },
  selectedSwitch: {
    status: "idle",
    error: null,
    data: null,
  },
  transactions: {
    status: "idle",
    error: null,
    data: [],
  },
  burnersMetadata: {
    status: "idle",
    error: null,
    data: {},
  },
};

const brightSwitchSlice = createSlice({
  name: "brightSwitch",
  initialState,
  reducers: {
    resetBrightSwitchStore: () => initialState,
    setSelectedSwitch(state, action) {
      state.selectedSwitch.data = action.payload;
    },
    setSelectedArtist(state, action) {
      state.selectedArtist.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllArtists.pending, (state) => {
        state.artists.status = "loading";
        state.artists.error = null;
      })
      .addCase(fetchAllArtists.fulfilled, (state, action) => {
        state.artists.status = "succeeded";
        state.artists.error = null;
        state.artists.data = action.payload;
      })
      .addCase(fetchAllArtists.rejected, (state) => {
        state.artists.status = "failed";
        state.artists.error = "Couldn't load artists";
      })
      .addCase(fetchAllSwitches.pending, (state) => {
        state.allSwitches.status = "loading";
        state.allSwitches.error = null;
      })
      .addCase(fetchAllSwitches.fulfilled, (state, action) => {
        state.allSwitches.status = "succeeded";
        state.allSwitches.error = null;
        state.allSwitches.data = action.payload;
      })
      .addCase(fetchAllSwitches.rejected, (state) => {
        state.allSwitches.status = "failed";
        state.allSwitches.error = "Couldn't load artist's switches";
      })
      .addCase(fetchArtistSwitches.pending, (state) => {
        state.artistSwitches.status = "loading";
        state.artistSwitches.error = null;
      })
      .addCase(fetchArtistSwitches.fulfilled, (state, action) => {
        state.artistSwitches.status = "succeeded";
        state.artistSwitches.error = null;
        state.artistSwitches.data = action.payload;
      })
      .addCase(fetchArtistSwitches.rejected, (state) => {
        state.artistSwitches.status = "failed";
        state.artistSwitches.error = "Couldn't load artist's switches";
      })
      .addCase(fetchTransactions.pending, (state) => {
        state.transactions.status = "loading";
        state.transactions.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions.status = "succeeded";
        state.transactions.error = null;
        state.transactions.data = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.transactions.status = "failed";
        state.transactions.error = null;
      })
      .addCase(fetchBurnersMetadata.pending, (state) => {
        state.burnersMetadata.status = "loading";
        state.burnersMetadata.error = null;
      })
      .addCase(fetchBurnersMetadata.fulfilled, (state, action) => {
        state.burnersMetadata.status = "succeeded";
        state.burnersMetadata.error = null;
        state.burnersMetadata.data = action.payload;
      })
      .addCase(fetchBurnersMetadata.rejected, (state) => {
        state.burnersMetadata.status = "failed";
        state.burnersMetadata.error = null;
      });
  },
});

export const { setSelectedSwitch, setSelectedArtist, resetBrightSwitchStore } =
  brightSwitchSlice.actions;

export const selectAllArtists = (state: RootState) =>
  state.brightSwitch.artists.data;
export const selectAllArtistsStatus = (state: RootState) =>
  state.brightSwitch.artists.status;
export const artistSwitches = (state: RootState) =>
  state.brightSwitch.artistSwitches;
export const selectedSwitch = (state: RootState) =>
  state.brightSwitch.selectedSwitch;
export const selectBurnersMetadata = (state: RootState) =>
  state.brightSwitch.burnersMetadata.data;
export const selectAllSwitches = (state: RootState) =>
  state.brightSwitch.allSwitches.data;
export const selectTransactions = (state: RootState) =>
  state.brightSwitch.transactions;

export default brightSwitchSlice.reducer;
