import { AuthController } from "@rfrsh/auth-library";
import { Connection } from "@solana/web3.js";
import { createRef } from "react";
import Home from "../pages/home";
import Switches from "../pages/switches";

// rpc connection
export const _heliusRPC = process.env.REACT_APP_HELIUS_RPC || "";

export const connection = new Connection(_heliusRPC, "processed");

// honeycomb
export const honeycombKey = process.env.REACT_APP_HONEYCOMB_PROJECT || "";

// api url
export const apiUrl =
  process.env.REACT_APP_NETWORK === "devnet"
    ? "https://api.rfrsh.io/"
    : "https://api.brightswitch.brightswitch.io/";

//* urls
const authServiceUrls = {
  url: process.env.REACT_APP_AUTH_SERVICE || "https://auth-service.rfrsh.io",
};
export const refreshTokenUrl = authServiceUrls.url + "/auth/refresh/";

//* auth instance
export const authController = new AuthController(authServiceUrls.url);

export const authAppName = process.env.REACT_APP_AUTH_APP || "";

// routes
export const routesName = {
  home: "/",
  switches: "/:artistKey",
};

export const routes = [
  {
    path: routesName.home,
    name: "home",
    element: <Home />,
    nodeRef: createRef(),
  },
  {
    path: routesName.switches,
    name: "switches",
    element: <Switches />,
    nodeRef: createRef(),
  },
];

export const emailPattern =
  /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const usernamePattern = /^[a-zA-Z][a-zA-Z0-9_-]{2,15}$/;

export const switchInfoEmptyObj = {
  name: "",
  cost: 0,
  limit: 0,
  minted: 0,
  endedAt: 0,
  startedAt: 0,
  collection_id: "",
  metadataImage: "",
  metadataName: "",
};

export const findArtistImagesByname: {
  [key: string]: string;
} = {
  ["Found In Far Away Places"]:
    "https://arweave.net/z9HKgy4I0wUf4x3JfNc0p9S2A8cZVr3YPLBoTyS3uBo",
  ["Venus of L-Arginine"]:
    "https://thcmt7uw5zddeiylks6fr2gnxw5uw3thudriavs32k6belf3qi2a.arweave.net/mcTJ_pbuRjIjC1S8WOjNvbtLbmeg4oBWW9K8Eiy7gjQ?ext=jpg",
};
