import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { routes } from "./constant";
import Providers from "./providers";
import reportWebVitals from "./reportWebVitals";

// css
import "@solana/wallet-adapter-react-ui/styles.css";
import "./classes.css";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === "/",
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
    })),
  },
  // {
  //   path: "/",
  //   element: <Maintance />,
  //   children: routes.map((route) => ({
  //     index: route.path === "/",
  //     path: route.path === "/" ? undefined : route.path,
  //     element: route.element,
  //   })),
  // },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Providers>
    <RouterProvider router={router} />
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
