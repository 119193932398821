import { useAppDispatch, useAppSelector } from "../../hooks";
import { useAuth } from "../../hooks/useAuth";
import Profile from "../../pages/profile";
import { selectProfileModal, setProfileModal } from "../../redux/slices/app";
import ModalComp from "../modal";

export default function ProfileModal() {
  const profileModal = useAppSelector(selectProfileModal);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const isUserNotValid =
    (auth.authInstance.user && !auth.authInstance.user.id) ||
    !auth.authInstance.user?.json.hasUsernameChanged ||
    auth.authInstance?.questions?.missing.length > 0;

  // useEffect(() => {
  //   if (profileModal && !auth.isUSerLoggedIn) {
  //     auth.handleLogin().then(() => {
  //       if (!auth.authInstance.user) dispatch(setProfileModal(false));
  //     });
  //   }
  // }, [profileModal, auth.isUSerLoggedIn]);

  if (!auth.isUSerLoggedIn) return null;
  return (
    <ModalComp
      closeIcon={false}
      width={"40rem"}
      setIsModalOpen={(state) =>
        isUserNotValid ? "" : dispatch(setProfileModal(state))
      }
      isModalOpen={isUserNotValid || profileModal}>
      <Profile isUserNotValid={!!isUserNotValid} />
    </ModalComp>
  );
}
