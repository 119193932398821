import { useCallback, useEffect, useReducer, useState } from "react";
import { TwitterIcon } from "../../assets";
import SelectBox from "../../components/selectBox";
import SpinLoader from "../../components/spinLoader";
import { emailPattern, usernamePattern } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectProfileModal, setProfileModal } from "../../redux/slices/app";
import { openNotification } from "../../utils";

// STYLES
import { useAuth } from "../../hooks/useAuth";
import "./styles.css";

type initialStateType = {
  username: string;
  usernameError: null | boolean;
  emailError: null | boolean;
  email: string;
  actionLoading: boolean;
};

const initialState = {
  username: "",
  usernameError: null,
  emailError: null,
  email: "",
  actionLoading: false,
};

export default function Profile({
  isUserNotValid,
}: {
  isUserNotValid: boolean;
}) {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState<any>([]);
  const profileModal = useAppSelector(selectProfileModal);
  const [state, setState] = useReducer(
    (state: initialStateType, newState: Partial<initialStateType>) => ({
      ...state,
      ...newState,
    }),
    initialState
  );

  // const isReqQuestionMissing = useMemo(
  //   () => auth.authInstance?.questions?.missing.length,
  //   [auth.authInstance?.questions?.missing.length]
  // );
  const isReqQuestionMissing = true;

  useEffect(() => {
    setQuestions(auth.authInstance?.questions?.questions);
  }, [auth.authInstance?.questions?.questions]);

  useEffect(() => {
    if (!auth.authInstance.user) setState(initialState);
    setState({
      username: auth.authInstance.user?.username.includes("deFaultUseR_")
        ? ""
        : auth.authInstance.user?.username || "",
      email: auth.authInstance.user?.email || "",
    });
  }, [auth.authInstance.user]);

  useEffect(() => {
    if (!profileModal) return;
    setStep(1);
  }, [profileModal]);

  const onSignUp = useCallback(async () => {
    try {
      if (questions.some((obj: any) => obj.required && !obj.answer))
        throw new Error("Please Answer required questions");

      setState({ actionLoading: true });
      await auth.authInstance.submitAnswers({
        answers: questions.map((obj: any) => ({
          question_id: obj.id,
          answer: obj.answer ?? "",
        })),
      });
      setState({ actionLoading: false });
      dispatch(setProfileModal(false));
      setStep(1);
    } catch (error: any) {
      setState({ actionLoading: false });
      openNotification({
        type: "error",
        title: error?.message,
      });
    }
  }, [questions]);

  const onProfileSave = useCallback(async () => {
    try {
      if (!usernamePattern.test(state.username)) {
        setState({ usernameError: true });
        throw new Error("Please Enter Valid User Name");
      } else if (!emailPattern.test(state.email)) {
        setState({ emailError: true });
        throw new Error("Please Enter Valid Email");
      }
      dispatch(setProfileModal(true));
      setState({ actionLoading: true });
      await auth.authInstance.updateUserInfo({
        email: state.email.trim(),
        username: state.username.trim(),
        ...(!auth.authInstance.user?.json.hasUsernameChanged && {
          json: {
            hasUsernameChanged: true,
          },
        }),
      });
      setStep(2);
      // if (isReqQuestionMissing) setStep(2);
      // else dispatch(setProfileModal(false));
    } catch (error: any) {
      console.dir(error.status);
      console.dir(error);
      openNotification({
        type: "error",
        title:
          error?.message ===
          "An error occurred while updating user information: AxiosError: Request failed with status code 400"
            ? "That username is already taken. Please try again."
            : error?.message,
      });
    } finally {
      setState({ actionLoading: false });
    }
  }, [
    state.email,
    state.username,
    auth.authInstance.user?.json.hasUsernameChanged,
  ]);

  const printQuestions = useCallback((obj: any, i: number) => {
    if (["Enum", "EnumMulti"].includes(obj.type)) {
      return (
        <div
          className={
            "select-container" + (obj.enum_optionsId == "0" ? " condensed" : "")
          }>
          <p className='select-title'>
            {obj.question} {obj.required && <span>*</span>}{" "}
          </p>
          <SelectBox
            multiselect={obj.type === "EnumMulti"}
            options={obj.question_enum_options.options}
            onChange={(value: string[]) => {
              setQuestions((prev: any) => {
                prev[i].answer = Array.isArray(value) ? value.join(",") : value;
                return [...prev];
              });
            }}
            value={obj?.answer}
          />
        </div>
      );
    } else if (obj.type === "Number") {
      return (
        <>
          <p className='input-title'>
            {obj.question} {obj.required && <span>*</span>}
          </p>
          <input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuestions((prev: any) => {
                prev[i].answer = e.target.value;
                return [...prev];
              });
            }}
            value={obj?.answer}
            type='number'
            name='userName'
            autoComplete='off'
            placeholder='Answer'
            min={1}
          />
        </>
      );
    } else {
      return (
        <>
          <p className='input-title'>
            {obj.question} {obj.required && <span>*</span>}
          </p>
          <input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuestions((prev: any) => {
                prev[i].answer = e.target.value;
                return [...prev];
              });
            }}
            value={obj?.answer}
            type='text'
            name='userName'
            autoComplete='off'
            placeholder='Answer'
          />
        </>
      );
    }
  }, []);

  return (
    <div className='Profile'>
      <div className={`card`}>
        <h2>{isUserNotValid ? "Sign Up for" : "Profile for"}</h2>
        <h1>GLASS</h1>
        {step == 1 ? (
          <>
            <input
              onChange={(e) => {
                if (usernamePattern.test(e.target.value)) {
                  setState({ usernameError: false });
                } else {
                  setState({ usernameError: true });
                }
                setState({ username: e.target.value });
              }}
              value={state.username}
              type='text'
              name='userName'
              autoComplete='off'
              placeholder='User Name*'
              className={`${state.usernameError && "req-error-show"}`}
            />
            <div
              className={`req-error ${
                state.usernameError && "req-error-show"
              }`}>
              Please Enter Valid User Name!
            </div>
            <input
              onChange={(e) => {
                if (emailPattern.test(e.target.value)) {
                  setState({ emailError: false });
                } else {
                  setState({ emailError: true });
                }

                setState({ email: e.target.value });
              }}
              value={state.email}
              type='email'
              placeholder='Email Address'
              className={`${state.emailError && "req-error-show"}`}
            />
            <div
              className={`req-error ${state.emailError && "req-error-show"}`}>
              Please Type valid Email!
            </div>
            {auth.connectedTwitter?.twitter_handle ? (
              <div style={{ cursor: "auto" }} className='social transition'>
                <TwitterIcon />
                <span>{auth.connectedTwitter.twitter_handle}</span>
              </div>
            ) : (
              <div
                onClick={() => auth.connectTwitter()}
                className='social transition'>
                <TwitterIcon />
                <span>Connect with X</span>
              </div>
            )}
          </>
        ) : (
          questions?.map(printQuestions)
        )}
        <button
          style={{
            background: "var(--cyan-blue)",
            padding: "10px",
          }}
          className='btn-primary'
          disabled={state.actionLoading}
          onClick={step == 1 ? onProfileSave : onSignUp}>
          {state.actionLoading ? (
            <SpinLoader />
          ) : step == 1 && isReqQuestionMissing ? (
            "Next"
          ) : step == 2 && isUserNotValid ? (
            "Sign Up"
          ) : (
            "Save"
          )}
        </button>
        <p className='signInRoute'>BrightSwitch is powered by GLASS</p>
      </div>
    </div>
  );
}

{
  /* <div
          onClick={() => auth.connectDiscord()}
          className='social discord transition'>
          <DiscordIcon/>
          <span>Connect Discord</span>
        </div>
            <div className='checkbox'>
              <input type='checkbox' />
              <p>
                I agree to the <span>Terms & Conditions</span>
              </p>
            </div>
            <div className='user-avatar'>
              <span>
                <img className='user-image' src={emptyUser} alt='user Image' />
                <EditIcon className='edit-icon' />
              </span>
            </div> */
}
{
  /* <ImagePickerModal /> */
}
