import { createSlice } from "@reduxjs/toolkit";
import { appState } from "../../types";
import { RootState } from "../store";

const initialState: appState = {
  appLoader: false,
  loginLoader: false,
  profileModal: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    resetAppStore: () => initialState,
    setProfileModal: (state, action) => {
      state.profileModal = action.payload;
    },
    setAppLoader(state, action) {
      state.appLoader = action.payload;
    },
    setLoginLoader(state, action) {
      state.loginLoader = action.payload;
    },
  },
});

export const { setAppLoader, resetAppStore, setProfileModal, setLoginLoader } =
  appSlice.actions;

export const selectAppLoader = (state: RootState) => state.app.appLoader;
export const selectLoginLoader = (state: RootState) => state.app.loginLoader;
export const selectProfileModal = (state: RootState) => state.app.profileModal;

export default appSlice.reducer;
