import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import { capitalizeWords } from "./utils";

const defaultTitles = {
  error: "Something went wrong",
  info: "Something happened",
  warning: "Warning",
  success: "Successful",
};

export const openNotification = ({
  title,
  description,
  type = "success",
  placement = "bottomRight",
  onClick = () => "",
  onClose = () => "",
  capitalize = true,
}: {
  title?: string;
  description?: string;
  type?: "success" | "info" | "warning" | "error";
  placement?: NotificationPlacement;
  onClick?: () => void;
  onClose?: () => void;
  capitalize?: boolean;
}) => {
  notification[type]({
    message: capitalize ? capitalizeWords(title ?? defaultTitles[type]) : title,
    description,
    placement,
    onClick,
    onClose,
  });
};
