import { Select } from "antd";

const SelectBox = ({
  multiselect,
  value,
  onChange,
  options,
}: {
  multiselect?: boolean;
  value: string;
  options: string[];
  onChange: any;
}) => {
  return multiselect ? (
    <Select
      value={value ? value.split(",") : undefined}
      mode='multiple'
      allowClear
      style={{ width: "100%" }}
      placeholder='Select'
      // defaultValue={["a10", "c12"]}
      onChange={onChange}
      options={options.map((option) => ({ value: option, label: option }))}
    />
  ) : (
    <Select
      value={value ? value : undefined}
      allowClear
      style={{ width: "100%" }}
      placeholder='Select'
      // defaultValue={["a10", "c12"]}
      onChange={onChange}
      options={options.map((option) => ({ value: option, label: option }))}
    />
  );
};

export default SelectBox;
