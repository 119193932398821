import { BatchesStatus, BulkStatus } from "@honeycomb-protocol/hive-control";
import { Switch } from "@rfrsh/brightswitch";
import { ArtistWithSwitchLength } from "../types";
import { getResumeSwitchKey } from "./localStorage";
// import { resetBrightSwitchStore } from "../redux/slices/brightSwitch";
// import { store } from "../redux/store";

export function statusUpdateHandle(
  _status: BulkStatus,
  setBatchProcessModal: any
) {
  const currentBatch =
    _status.main.currentBatch +
    _status.pre.currentBatch +
    _status.post.currentBatch;
  const status: BatchesStatus = {
    currentBatchStatus:
      currentBatch < _status.pre.totalBatches
        ? _status.pre.currentBatchStatus
        : currentBatch < _status.main.totalBatches + _status.pre.totalBatches
        ? _status.main.currentBatchStatus
        : _status.post.currentBatchStatus,
    totalBatches:
      (isNaN(_status.main.totalBatches) ? 1 : _status.main.totalBatches) +
      (isNaN(_status.pre.totalBatches) ? 1 : _status.pre.totalBatches) +
      (isNaN(_status.post.totalBatches) ? 1 : _status.post.totalBatches),
    totalOperations:
      _status.main.totalOperations +
      _status.pre.totalOperations +
      _status.post.totalOperations,
    currentBatch,
    successfulOperations:
      _status.main.successfulOperations +
      _status.pre.successfulOperations +
      _status.post.successfulOperations,
    failedOperations:
      _status.main.failedOperations +
      _status.pre.failedOperations +
      _status.post.failedOperations,
    confirmedContexts: _status.pre.confirmedContexts
      .concat(_status.main.confirmedContexts)
      .concat(_status.post.confirmedContexts),
  };
  const isFailed = status.failedOperations > 0;

  const isSucceed = status.successfulOperations === status.totalOperations;
  const txnSignature = isSucceed && _status.main.confirmedContexts[0].signature;

  setBatchProcessModal({
    modal: true,
    totalBatches: status.totalBatches || 1,
    currentBatch: status.currentBatch,
    currentBatchStatus: status.currentBatchStatus,
    inProcess:
      (status.currentBatch !== status.totalBatches ||
        status.currentBatchStatus < 4) &&
      status.successfulOperations !== status.totalOperations &&
      isFailed == false,
    isFailed,
    isSucceed,
    txnSignature,
  });
}

export function isSwitchResumeHandle(_switch: Switch) {
  return getResumeSwitchKey() === _switch.address().toString();
}

export function is3dLink(url: string | undefined | null) {
  return url?.includes("?ext=glb");
}

export function isMP4Link(url: string | undefined | null) {
  return url?.includes("?ext=mp4") || url?.includes("?ext=ogg");
}

export const setBurnersHandel = async (
  _switch: Switch,
  setBurners: any,
  refetch = false
) => {
  if (!_switch) return;
  const artist = _switch.artist;
  const switches = await artist.switches();
  const balance = await artist.userBalance(refetch).catch(() => undefined);
  const address = getResumeSwitchKey();
  if (
    balance &&
    (!address || !switches.find((sw) => sw.address().toString() === address))
  ) {
    localStorage.setItem("resumeSwitch", _switch.address().toString());
  }

  await _switch
    .getEligibleBurners(true)
    .then((_burners) => {
      setBurners(_burners);
    })
    .catch((error) => {
      console.log("Error:", error);
    });
};

export const getArtistHandle = (
  artists: ArtistWithSwitchLength[],
  artistKey: string | undefined
) =>
  artists.find(
    (a) =>
      a.artist.address().toString().toLowerCase() === artistKey?.toLowerCase()
  )?.artist;

// export const clearReduxStatesHandle = () => {
//   store.dispatch(resetBrightSwitchStore());
// };
