import {
  WalletLike,
  guestIdentity,
  walletIdentity,
} from "@honeycomb-protocol/hive-control";
import {
  WalletMultiButton,
  useWalletModal,
} from "@solana/wallet-adapter-react-ui";
import { LegacyRef, Ref, useEffect } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { logo, logo2x } from "./assets";
import ProfileModal from "./components/profileModal";
import SpinLoader from "./components/spinLoader";
import { routes, routesName } from "./constant";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useAuth } from "./hooks/useAuth";
import { selectLoginLoader, setProfileModal } from "./redux/slices/app";
import {
  fetchAllArtists,
  fetchAllSwitches,
  fetchBurnersMetadata,
} from "./redux/slices/brightSwitch";
import { initHoneycomb, selectHC } from "./redux/slices/honeycomb";
import {
  addBtnInWalletDropdownEnd,
  addBtnInWalletDropdownStart,
  getRefreshToken,
} from "./utils";

export default function App() {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { setVisible: setWalletModal } = useWalletModal();
  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {};
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const honeycomb = useAppSelector(selectHC);
  const loginLoader = useAppSelector(selectLoginLoader);

  useEffect(() => {
    dispatch(initHoneycomb()).then(() => {
      dispatch(fetchAllArtists());
      dispatch(fetchAllSwitches());
    });
  }, [auth.walletAddress]);

  useEffect(() => {
    dispatch(fetchBurnersMetadata());
  }, []);

  useEffect(() => {
    if (!honeycomb) return;
    if (auth.isWalletConnected) {
      honeycomb.use(walletIdentity(auth.wallet as WalletLike));
      honeycomb.bright().loadUsersAssets();
    } else {
      honeycomb.use(guestIdentity());
    }
  }, [honeycomb, auth.isWalletConnected]);

  useEffect(() => {
    if (auth.isUSerLoggedIn == false && !!getRefreshToken()) {
      auth.handleRefresh();
    }
  }, [auth.isUSerLoggedIn]);

  useEffect(() => {
    if (
      auth.isWalletConnected &&
      auth.isUSerLoggedIn == false &&
      !getRefreshToken()
    ) {
      auth.handleLogin();
    }
  }, [auth.isUSerLoggedIn, auth.isWalletConnected]);

  // add buttons in wallet dropdown
  useEffect(() => {
    addBtnInWalletDropdownStart(
      auth.isUSerLoggedIn ? "add" : "remove",
      "Profile",
      () => dispatch(setProfileModal(true))
    );

    addBtnInWalletDropdownEnd(
      auth.isUSerLoggedIn ? "add" : "remove",
      "Log Out",
      async () => {
        await auth.wallet.disconnect();
        auth.handleLogout();
      }
    );
  }, [auth.isUSerLoggedIn]);

  return (
    <>
      <div className='header'>
        <span className='wallet-button'>
          <WalletMultiButton disabled={loginLoader}>
            {auth.isUSerLoggedIn && auth.isWalletConnected ? (
              <div className='profile'>
                <div className='user-name'>
                  {auth.authInstance.user?.username.includes("deFaultUseR_")
                    ? "user"
                    : auth.authInstance.user?.username}{" "}
                </div>
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (auth.isWalletConnected) {
                    auth.handleLogin();
                  } else {
                    setWalletModal(true);
                  }
                }}
                style={{ width: "166px", marginInline: "-33px" }}>
                {loginLoader ? <SpinLoader /> : "Sign In"}
              </div>
            )}
          </WalletMultiButton>
        </span>
        <div className='t-c'>
          <picture onClick={() => navigate(routesName.home)}>
            <source srcSet={logo} media='(max-width: 600px)' />
            <img src={logo2x} alt='logo' />
          </picture>
        </div>
      </div>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef as Ref<HTMLElement | undefined>}
          timeout={250}
          classNames='page'
          unmountOnExit>
          {() => (
            <div
              ref={nodeRef as LegacyRef<HTMLDivElement> | undefined}
              className='page'>
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
      <ProfileModal />
    </>
  );
}
