import React from "react";

import { useWallet } from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { authAppName, authController } from "../constant";
import { useAppDispatch } from "../hooks";
import { setLoginLoader } from "../redux/slices/app";
import {
  deleteTokens,
  getRefreshToken,
  openNotification,
  setAccessToken,
  setRefreshToken,
} from "../utils";

const useAuth = () => {
  const [counter, setCounter] = useState<number>(0);
  const wallet = useWallet();
  const dispatch = useAppDispatch();

  //! useMemos
  const authInstance = useMemo(() => authController, [counter]);

  const connectedTwitter = useMemo(
    () => authInstance.twitter,
    [authInstance.twitter?.user_id]
  );
  const isDiscordConnected = useMemo(
    () => !!authInstance.discord?.user_id,
    [authInstance.discord?.user_id]
  );
  const walletAddress = useMemo(
    () => wallet.publicKey?.toString(),
    [wallet.publicKey?.toString()]
  );
  const isUSerLoggedIn = useMemo(
    () => !!authInstance.user?.id,
    [authInstance.user?.id]
  );
  const isWalletAdded = useMemo(
    () =>
      authInstance?.user?.id &&
      authInstance.wallets.some(({ address }) => address === walletAddress),
    [walletAddress, authInstance.wallets, authInstance.user]
  );
  const isWalletConnected = useMemo(() => wallet.connected, [wallet.connected]);
  const walletPubKey = useMemo(
    () => wallet.wallet?.adapter.publicKey || null,
    [wallet.wallet]
  );

  const getQuestions = useCallback(async () => {
    try {
      await authInstance.getQuestions();
    } catch (error: any) {
      console.dir(error);
      openNotification({
        title: error.message,
        type: "error",
      });
    }
  }, []);

  //! useEffects
  useEffect(() => {
    const handleAuthEmitter = () => {
      setCounter((a) => a + 1);
    };
    authController.on("AuthEmitter", handleAuthEmitter);
    return () => {
      authController.off("AuthEmitter", handleAuthEmitter);
    };
  }, []);

  //! useCallbacks
  const handleLogin = useCallback(async () => {
    try {
      dispatch(setLoginLoader(true));
      await authInstance.handleAuthentication(wallet);
      setAccessToken(authInstance.accessToken!);
      setRefreshToken(authInstance.refreshToken!);
      // window.location.reload();
    } catch (error: any) {
      openNotification({
        title: error.message,
        type: "error",
      });
      console.dir(error);
    } finally {
      dispatch(setLoginLoader(false));
    }
  }, [wallet, authInstance]);

  const handleRefresh = useCallback(async () => {
    try {
      dispatch(setLoginLoader(true));
      await authInstance.refresh(getRefreshToken() || "");
      setAccessToken(authInstance.accessToken!);
      setRefreshToken(authInstance.refreshToken!);
    } catch (error: any) {
      deleteTokens();
      // redirect(routesName.home);
      console.dir(error);
      openNotification({
        title: error.message,
        type: "error",
      });
    } finally {
      dispatch(setLoginLoader(false));
    }
  }, [authInstance]);

  const handleLogout = useCallback(async () => {
    try {
      authInstance.logout();
      deleteTokens();
      // window.location.reload();
      // clearReduxStatesHandle();
      // redirect(routesName.home);
    } catch (error: any) {
      console.dir(error);
      openNotification({
        title: error.message,
        type: "error",
      });
    }
  }, []);

  const connectTwitter = useCallback(() => {
    try {
      const redirectUrl = authInstance.initiateTwitterAuth(authAppName);
      const popup = window.open(
        redirectUrl,
        "_blank",
        "no referrer, width=600,height=600"
      );
      if (!popup) throw new Error("Popup blocked");
      var timer = setInterval(function () {
        if (popup.closed) {
          clearInterval(timer);
          authInstance.refresh(getRefreshToken() || "");
        }
      }, 1000);
      // router.push(redirectUrl);
    } catch (error: any) {
      console.dir(error);
      openNotification({
        title: error.message,
        type: "error",
      });
    }
  }, []);

  const connectDiscord = useCallback(() => {
    try {
      const redirectUrl = authInstance.initiateDiscordAuth(authAppName);
      window.open(
        redirectUrl,
        "_blank",
        "no opener, no referrer, width=600,height=600"
      );
      // router.push(redirectUrl);
    } catch (error: any) {
      console.dir(error);
      openNotification({
        title: error.message,
        type: "error",
      });
    }
  }, []);

  return {
    handleLogin,
    wallet,
    walletAddress,
    handleLogout,
    handleRefresh,
    isWalletConnected,
    authInstance,
    connectTwitter,
    connectDiscord,
    isUSerLoggedIn,
    isWalletAdded,
    connectedTwitter,
    isDiscordConnected,
    getQuestions,
    walletPubKey,
  };
};

export const AuthContext = React.createContext(
  {} as ReturnType<typeof useAuth>
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
