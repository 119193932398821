import { SwitchDetails } from "../../types";
import ModelViewer from "../modelViewer";
import "./styles.css";

export default function NftDetail({
  switchDetail,
}: {
  switchDetail: Partial<SwitchDetails> & { count?: any };
}) {
  return (
    <>
      <div className='nft-image'>
        <span>{switchDetail?.count || 1}</span>
        <ModelViewer url={switchDetail?.metadataImage} heightWidth />
      </div>
      <div>
        <h4 className='nft-name'>{switchDetail?.name}</h4>
        <p className='nft-metadata-name'>{switchDetail.metadataName}</p>
      </div>
    </>
  );
}
