import {
  Honeycomb,
  HoneycombProject,
  lutModule,
} from "@honeycomb-protocol/hive-control";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BrightSwitch } from "@rfrsh/brightswitch";
import { PublicKey } from "@solana/web3.js";
import { _heliusRPC, connection, honeycombKey } from "../../constant";
import { HoneycombState } from "../../types";
import { openNotification } from "../../utils";
import { RootState } from "../store";

export const initHoneycomb = createAsyncThunk<Honeycomb>(
  "honeycomb/init",
  async () => {
    try {
      const project = await HoneycombProject.fromAddress(
        new Honeycomb(connection),
        new PublicKey(honeycombKey)
      );

      const honeycomb = new Honeycomb(connection, {
        confirmOptions: {
          commitment: "processed",
        },
      });

      honeycomb
        .use(project)
        .use(new BrightSwitch(_heliusRPC))
        .use(
          lutModule(() => {
            throw new Error("User should not be able to call this");
          })
        );

      return honeycomb;
    } catch (error: any) {
      openNotification({
        type: "error",
        title: "Error initializing honeycomb",
      });
      console.error("Error initializing honeycomb:", error);
      throw new Error(error);
    }
  }
);

const initialState: HoneycombState = {
  status: "idle",
  error: null,
  honeycomb: null,
};

const honeycombSlice = createSlice({
  name: "honeycomb",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initHoneycomb.pending, (state) => {
        state.status = "loading";
      })
      .addCase(initHoneycomb.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.honeycomb = action.payload;
      })
      .addCase(initHoneycomb.rejected, (state) => {
        state.status = "failed";
        state.error = "Couldn't initialize Honeycomb";
      });
  },
});

export const selectHC = (state: RootState) => state.honeycomb.honeycomb;
export const selectHCStatus = (state: RootState) => state.honeycomb.status;

export default honeycombSlice.reducer;
