import { BulkStatus, SendBulkOptions } from "@honeycomb-protocol/hive-control";
import { EditionInfos, Switch } from "@rfrsh/brightswitch";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader";
import Layout from "../../components/main-section";
import ModelViewer from "../../components/modelViewer";
import IndividualSwitch from "../../components/switch";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useAuth } from "../../hooks/useAuth";
import {
  artistSwitches,
  fetchArtistSwitches,
  selectAllArtists,
} from "../../redux/slices/brightSwitch";
import { SwitchDetails } from "../../types";
import {
  generateUUID,
  getArtistHandle,
  openNotification,
  setBurnersHandel,
  statusUpdateHandle,
} from "../../utils";

// STYLE
import "./styles.css";
import editions from "./edditionInfos.json";

export default function Switches() {
  const dispatch = useAppDispatch();
  const { artistKey } = useParams();
  const { data: switches, status: switchesStatus } =
    useAppSelector(artistSwitches);
  const artists = useAppSelector(selectAllArtists);
  const auth = useAuth();
  const artist = getArtistHandle(artists, artistKey);

  useEffect(() => {
    if (!artistKey || !artist) return;
    dispatch(fetchArtistSwitches(artist));
  }, [artist, artistKey]);

  const isUserValid = useCallback(() => {
    try {
      if (!auth.isUSerLoggedIn || !auth.isWalletConnected)
        throw new Error("Please Sign in First");

      return true;
    } catch (error: any) {
      openNotification({
        type: "error",
        title: error.message,
      });
      return false;
    }
  }, [auth.isWalletConnected, auth.isUSerLoggedIn]);

  async function switchNfts({
    _switch,
    setBurners,
    setSwitchDetail,
    setSwitchLoader,
    setBatchProcessModal,
  }: {
    _switch: Switch;
    setBurners: any;
    setSwitchLoader: any;
    setSwitchDetail: any;
    setBatchProcessModal: (_obj?: object) => void;
  }) {
    if (isUserValid() == false) return;

    try {
      setSwitchLoader(true);
      const options: SendBulkOptions & {
        editionInfos?: EditionInfos;
      } = {
        statusUpdate: (status: BulkStatus) =>
          statusUpdateHandle(status, setBatchProcessModal),
      };

      await _switch?.switchBurnNFTs(process.env.REACT_APP_SHYFT_API_KEY || "", {
        ...options,
        commitment: "processed",
        preflightCommitment: "processed",
        editionInfos: editions,
      });

      openNotification({ title: "Switch Successful" });
      setSwitchDetail((obj: SwitchDetails) => {
        obj.minted += 1;
        return { ...obj };
      });
    } catch (e) {
      setBatchProcessModal((_obj: any) => ({
        ..._obj,
        modal: true,
        isFailed: true,
        isSucceed: false,
        inProcess: false,
      }));
      openNotification({ title: "Switch Failed", type: "error" });
      console.error(e);
    } finally {
      setBurnersHandel(_switch, setBurners, true);
      setSwitchLoader(false);
    }
  }

  if (switchesStatus !== "succeeded") return <Loader className="app-loader" />;
  return (
    <Layout className="switch-detail">
      <section className="hero padding-block">
        <div>
          <h2>{artist?.data().name}</h2>
          <p>{artist?.metadata().description}</p>
          <a
            style={{
              backgroundColor: "var(--blue-sapphire)",
              marginTop: "20px",
            }}
            className="burn-btn"
            href="#switches"
          >
            View Switches
          </a>
        </div>
        <div>
          <div style={{ width: "max-content", textAlign: "center" }}>
            <ModelViewer
              url={
                artist?.metadata()?.animated_uri ||
                artist?.metadata()?.image_uri
              }
              heightWidth
              width="250px"
              height="250px"
            />
            <h2>{artist?.data().name}</h2>
            <p>
              {switches.length} Available Switch{switches.length > 1 && "es"}
            </p>
          </div>
        </div>
      </section>
      <section id="switches" className="switches padding-block">
        <h3 className="heading">Available Switches</h3>
        {switches.length ? (
          <div>
            {switches.map((_switch: Switch, i) => (
              <IndividualSwitch
                key={i}
                _switch={_switch}
                switchNfts={switchNfts}
              />
            ))}
          </div>
        ) : (
          <h3 className="t-c">No Switches Available</h3>
        )}
      </section>
      <section className="stats dark-primary-back padding-block">
        <h3>All-Time Stats</h3>
        <div className="nft-container">
          {switches?.map((sw) => (
            <div
              style={{ cursor: "auto" }}
              key={generateUUID()}
              className={`card`}
            >
              <ModelViewer
                borderRadius
                url={
                  sw.metadata()?.content?.links.animation_url ||
                  sw.metadata()?.content?.links.image
                }
                heightWidth
                width="268px"
                height="268px"
              />
              <h4>{sw?.data()?.name}</h4>
              <div>
                <p>Total: {Number(sw.data().limit)} </p>
                <p>Circulating Supply: {Number(sw.data().minted)}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
}
