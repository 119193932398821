import { ReactNode } from "react";
import "./styles.css";

export default function Layout({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <section className={`main-layout ${className || ""}`}>{children}</section>
  );
}
