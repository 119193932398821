import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { logoLarge } from "../../assets";
import Loader from "../../components/loader";
import Layout from "../../components/main-section";
import ModelViewer from "../../components/modelViewer";
import { routesName } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  fetchTransactions,
  selectAllArtists,
  selectAllSwitches,
  selectTransactions,
} from "../../redux/slices/brightSwitch";
import { generateUUID } from "../../utils";
import jsonFile from "./artists.json";

// STYLES
import "./styles.css";

export default function Home() {
  const navigate = useNavigate();
  const artists = useAppSelector(selectAllArtists);
  const dispatch = useAppDispatch();
  const allSwitches = useAppSelector(selectAllSwitches);
  const { data: transactions, status: transactionsStatus } =
    useAppSelector(selectTransactions);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  const featuredArtist = useMemo(() => {
    if (artists?.length) {
      return artists.map(({ artist, switchesLength }) => ({
        artistAddress: artist.address().toString(),
        switchesLength,
        image: artist.metadata()?.animated_uri || artist.metadata()?.image_uri,
      }));
    } else {
      return jsonFile.artists;
    }
  }, [artists]);

  return (
    <Layout className='home'>
      <section className='hero padding-block'>
        <div className='tex-container'>
          <h1 className='heading'>
            Burn and claim your next digital assets with BrightSwitch
          </h1>
          {/* <h4 className="subtitle">
            Create custom landing pages with Omega that convert more visitors
            than any website. With lots of unique blocks, you can easily build a
            page without coding.
          </h4> */}
          <a className='burn-btn' href='#burn-section'>
            LET IT BURN!
          </a>
        </div>
        <img src={logoLarge} alt='logo' />
      </section>
      <section id='burn-section' className='burn-section padding-block'>
        <h3>Featured Creators</h3>
        <div className='featured-creators scroll-bar'>
          {featuredArtist.map((artist, i) => {
            return (
              <div
                key={i}
                className='artist-card'
                onClick={() =>
                  navigate(
                    routesName.switches.replace(
                      ":artistKey",
                      artist.artistAddress
                    )
                  )
                }>
                <ModelViewer
                  url={artist.image}
                  borderRadius
                  heightWidth
                  width='300px'
                  height='300px'
                />
                <p className='legend'>
                  {artist.switchesLength} Available Switch
                  {artist.switchesLength > 1 && "es"}
                </p>
              </div>
            );
          })}
        </div>
      </section>
      <section className='realtime-activity padding-block'>
        <h3>Real-time Activity</h3>
        {transactions?.length ? (
          <div className='table scroll-bar'>
            {transactions.map((tableColumn: any, i) => (
              <div key={i}>
                <h4>{tableColumn.title}</h4>
                {tableColumn.list.map((a: string, i: number) =>
                  a.includes("https") ? (
                    <p
                      className='c-p u-l'
                      key={i}
                      onClick={() => window.open(a)}>
                      {a.slice(27)}
                    </p>
                  ) : (
                    <p key={i}>{a}</p>
                  )
                )}
              </div>
            ))}
          </div>
        ) : (
          <div style={{ justifySelf: "center" }}>
            {(transactionsStatus === "loading" && <Loader />) || (
              <p>Not Available</p>
            )}
          </div>
        )}
      </section>
      <section className='stats home-states padding-block'>
        <h3>Switches</h3>
        <div className='nft-container'>
          {(allSwitches?.length ? allSwitches : jsonFile.switches)?.map(
            (switchDetail) => (
              <div
                onClick={() =>
                  navigate(
                    routesName.switches.replace(
                      ":artistKey",
                      switchDetail.artistAddress
                    )
                  )
                }
                key={generateUUID()}
                className={`card`}>
                <ModelViewer
                  borderRadius
                  url={switchDetail.image}
                  heightWidth
                  width='268px'
                  height='268px'
                />
                <h4>{switchDetail.name}</h4>
                <div>
                  <p>Total: {switchDetail.total} </p>
                  <p>Circulating Supply: {switchDetail.claimed}</p>
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </Layout>
  );
}
