import { EligibleBurner, Switch } from "@rfrsh/brightswitch";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useMemo, useState } from "react";
import { TensorIcon, TimeIcon, XrayIcon, fireWork } from "../../assets";
import { switchInfoEmptyObj } from "../../constant";
import { useAppSelector } from "../../hooks";
import {
  selectAllArtistsStatus,
  selectBurnersMetadata,
} from "../../redux/slices/brightSwitch";
import { BurnerMetadata, SwitchDetails } from "../../types";
import { isSwitchResumeHandle, setBurnersHandel } from "../../utils";
import NftDetail from "../NftDetail";
import Loader from "../loader";
import ModalComp from "../modal";
import SpinLoader from "../spinLoader";
import "./styles.css";

const batchProcessModalDefaultObj = {
  modal: false,
  totalBatches: 0,
  currentBatch: 0,
  inProcess: false,
  isFailed: false,
  isSucceed: false,
  txnSignature: "",
};

const MintInfoIcons = ({
  mint,
}: {
  mint: string | undefined | null | BurnerMetadata;
}) => {
  if (typeof mint === "object") {
    var collection = mint?.collection;
    mint = mint?.mint;
  }

  return (
    <div className={`icons`}>
      <TensorIcon
        onClick={() =>
          window.open(`https://tensor.trade/trade/${collection ?? mint}`)
        }
        className={mint ?? "disable"}
      />
      <XrayIcon
        onClick={() =>
          window.open(
            `https://xray.helius.xyz/token/${mint}${
              process.env.REACT_APP_DEV_QUERY || ""
            }`
          )
        }
        className={mint ?? "disable"}
      />
    </div>
  );
};

export default function IndividualSwitch({
  _switch,
  switchNfts,
}: {
  _switch: Switch;
  switchNfts: any;
}) {
  const [switchLoader, setSwitchLoader] = useState<boolean>(false);
  const [burners, setBurners] = useState<EligibleBurner[]>([]);
  const [batchProcessModal, setBatchProcessModal] = useState(
    batchProcessModalDefaultObj
  );
  const [switchDetail, setSwitchDetail] =
    useState<SwitchDetails>(switchInfoEmptyObj);
  const Wallet = useWallet();
  const isSwitchResume = isSwitchResumeHandle(_switch);
  const artistsStatus = useAppSelector(selectAllArtistsStatus);
  const burnersMetadata = useAppSelector(selectBurnersMetadata);
  const disableButton = useMemo(
    () =>
      burners.some(
        (obj: EligibleBurner) => obj.eligibleAssets.length < obj.actualCount
      ) || switchDetail.endedAt < Date.now(),
    [burners, switchDetail.endedAt]
  );

  useEffect(() => {
    setBurnersHandel(_switch, setBurners);

    const _switchDetail = _switch?.data();
    if (!_switchDetail) return;

    const metadata = _switch.metadata() || {};
    setSwitchDetail({
      name: _switchDetail.name,
      cost: Number(_switchDetail.cost),
      limit: Number(_switchDetail.limit),
      minted: Number(_switchDetail.minted),
      endedAt: Number(_switchDetail.endedAt) * 1000,
      startedAt: Number(_switchDetail.startedAt) * 1000,
      collection_id: _switchDetail.collection?.toString() || "",
      metadataImage:
        metadata.content?.links.animation_url || metadata.content?.links.image,
      metadataName: metadata.content.metadata.name || "",
    });
  }, [_switch, Wallet.connected]);

  const burnerMArkup = (obj: EligibleBurner, i: number) => {
    const suggestedCount = obj.suggestedCount;
    const resumeCount = obj.actualCount;
    const count =
      resumeCount != suggestedCount ? (
        <>
          {`${obj.eligibleAssets.length}/${resumeCount}`}
          <span className='l-t'>
            {suggestedCount.toString().padStart(2, "0")}
          </span>
        </>
      ) : (
        `${obj.eligibleAssets.length}/${obj.suggestedCount}`
      );

    const image =
      obj.burner.metadata()?.animation_url || obj.burner.metadata()?.image;

    return (
      <div key={i} className='card'>
        <p>burn</p>
        <div className='details'>
          <NftDetail
            switchDetail={{
              name: obj.burner?.data()?.name,
              metadataName: obj.burner?.metadata()?.name || "",
              metadataImage: image,
              count,
            }}
          />
          <MintInfoIcons
            mint={burnersMetadata?.[obj.burner.address().toString()]}
          />
        </div>
      </div>
    );
  };

  const switchNftsHandle = () =>
    switchNfts({
      _switch,
      setBurners,
      setSwitchDetail,
      setSwitchLoader,
      setBatchProcessModal,
    });

  if (!burners.length || artistsStatus == "loading") return <Loader />;
  return (
    <>
      <div className='switch'>
        <div className='switch-container'>
          <div className='available'>{burners?.map(burnerMArkup)}</div>
          <div className='available receive'>
            <div className='card'>
              <p>Receive</p>
              <div className='details'>
                <NftDetail switchDetail={switchDetail} />
                <MintInfoIcons mint={switchDetail.collection_id} />
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => switchNftsHandle()}
          className={`switch-btn btn-primary blue-Sapphire ${
            (disableButton || switchLoader) && "disable"
          } ${disableButton && "disable-btn-color"}`}>
          {switchLoader ? (
            <SpinLoader />
          ) : disableButton ? (
            "Switch Unavailable"
          ) : isSwitchResume ? (
            "Resume"
          ) : (
            "Switch it!"
          )}
        </button>
        <p>Switch should be immediate but can take up to 2 mins.</p>
        <p>Multiple transaction signatures required. Retry until successful.</p>
        <p>
          {Number(switchDetail.limit)} Total Switches |{" "}
          {Number(switchDetail.minted)} Claimed |{" "}
          {Number(switchDetail.limit) - Number(switchDetail.minted)} Remaining
        </p>
      </div>
      <ModalComp
        closeIcon={batchProcessModal.inProcess ? false : true}
        width={"60rem"}
        className='batch-modal'
        setIsModalOpen={() =>
          !batchProcessModal.inProcess &&
          setBatchProcessModal(batchProcessModalDefaultObj)
        }
        isModalOpen={batchProcessModal?.modal}>
        {batchProcessModal.isFailed && (
          <>
            <h1 className='failed'>Batch Failed.</h1>
            <button
              onClick={() => {
                setBatchProcessModal(batchProcessModalDefaultObj);
                switchNftsHandle();
              }}>
              Retry Batch
            </button>
            {/* <p
              onClick={() => {
                setBatchProcessModal(batchProcessModalDefaultObj)
              }}
              className='c-p'>
              Cancel batch
            </p> */}
          </>
        )}
        {batchProcessModal.inProcess && (
          <>
            <h1>
              {`Processing batch ${batchProcessModal.currentBatch}/${batchProcessModal.totalBatches}...`}
            </h1>
            <p className='subtitle'>
              Do not close this window or push BACK on your browser.
            </p>
            <TimeIcon />
            <h1>Processing...</h1>
          </>
        )}
        {(batchProcessModal.inProcess || batchProcessModal.isFailed) && (
          <div className='progress'>
            {Array(batchProcessModal?.totalBatches)
              .fill(1)
              .map((_, i) => (
                <div
                  className={`${
                    i + 1 < batchProcessModal?.currentBatch
                      ? "done-color"
                      : i + 1 === batchProcessModal?.currentBatch
                      ? (batchProcessModal.isFailed && "error-color") ||
                        "continue-color"
                      : ""
                  }`}
                  key={i}></div>
              ))}
          </div>
        )}
        {batchProcessModal.isSucceed && (
          <div className='success'>
            <img src={fireWork} alt='fireWork' />
            <div
              onClick={() => {
                window.open(
                  `https://xray.helius.xyz/tx/${
                    batchProcessModal.txnSignature
                  }${process.env.REACT_APP_DEV_QUERY || ""}`
                );
              }}
              className='flex-center c-p'>
              <h1>Success!</h1>
              <NftDetail switchDetail={switchDetail} />
            </div>
            <img src={fireWork} alt='fireWork' />
          </div>
        )}
      </ModalComp>
    </>
  );
}
