import { combineReducers } from "@reduxjs/toolkit";
import brightSwitchReducer from "./slices/brightSwitch";
import honeycombReducer from "./slices/honeycomb";
import appReducer from "./slices/app";

const reducers = combineReducers({
  brightSwitch: brightSwitchReducer,
  honeycomb: honeycombReducer,
  app: appReducer,
});

export default reducers;
